<template>
  <section class="pb-2">
    <b-card body-class="p-0">
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">In vendita al privato </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Crea Preventivo</b-breadcrumb-item>
            <b-breadcrumb-item active> Dati Auto</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <div class="mt-2">
        <form-wizard class="shadow-none" color="#377DFF" :title="null" :subtitle="null" shape="square">
            <tab-content title="Dati Auto">
            <h2 class="text-primary-light">Dati auto selezionata: </h2>

            <b-row class="py-2">
                <b-col md="3">
                    <b-form-group label="Data ingresso">
                        <b-form-input placeholder="gg/mm/aaaa" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Marca">
                        <b-form-input placeholder="Marca Auto" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Modello">
                        <b-form-input placeholder="Modello Auto" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Versione">
                        <b-form-input placeholder="Versione" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Tipo">
                        <b-form-input placeholder="Tipo" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Tipo Veicolo">
                        <b-form-input placeholder="Tipo Veicolo" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Immatricolazione">
                        <b-form-input placeholder="Immatricolazione" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Chilometraggio">
                        <b-form-input placeholder="Chilometraggio" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Alimentazione">
                        <b-form-input placeholder="Alimentazione" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Colore">
                        <b-form-input placeholder="Colore" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Targa">
                        <b-form-input placeholder="Targa" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Telaio">
                        <b-form-input placeholder="Telaio Auto" plaintext/>
                    </b-form-group>
                </b-col>

                <b-col md="3">
                    <b-form-group label="Ubicazione">
                        <b-form-input placeholder="Ubicazione Auto" plaintext/>
                    </b-form-group>
                </b-col>
            </b-row>
            </tab-content>

            <tab-content title="Servizi e Accessori">

                <h2 class="text-primary-light">Servizi:</h2>
                
                <b-row class="mt-2">
                    <b-col md="6">
                        <b-form-group label-align="left" label-cols="2" label="Servizio 1">
                            <b-form-input placeholder="servizio 1"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-for="i in 4" :key="i">
                    <b-col md="6">
                        <b-form-group label-align="left" label-cols="2" :label="'Servizio ' + (i + 1)">
                            <v-select />
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label-align="left" label-cols="2" label="Prezzo">
                            <b-form-input placeholder="Inserisci il prezzo del servizio in €" />
                        </b-form-group>
                    </b-col>
                </b-row>

                <h2 class="text-primary-light my-2">Accessori del veicolo: </h2>

                <b-row v-for="i in 5" :key="i">
                    <b-col md="6">
                        <b-form-group label-align="left" label-cols="2" :label="'Accessorio ' + i">
                            <b-form-input placeholder="Nome Accessorio" />
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label-align="left" label-cols="2" label="Prezzo">
                            <b-form-input placeholder="Inserisci il prezzo dell'accessorio in €" />
                        </b-form-group>
                    </b-col>
                </b-row>

            </tab-content>

            <tab-content title="Dati permuta">
                <h2 class="text-primary-light my-2"> Dati della permuta (se presente)</h2>

                <b-row>
                    <b-col md="4">
                        <b-form-group label="Nome intestatario">
                            <b-form-input placeholder="Testo nome intestatario..."/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Cognome intestatario">
                            <b-form-input placeholder="Testo cognome intestatario..."/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Data di nascita">
                            <b-form-input placeholder="gg/mm/aa"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Codice Fiscale">
                            <b-form-input placeholder="000000000000000"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Marca">
                            <b-form-input placeholder="Specifica la marca..."/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Modello/Allestimento">
                            <b-form-input placeholder="Specifica il modello/allestimento"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Anno d'immatricolazione">
                            <b-form-input placeholder="aaaa"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Colore della permuta">
                            <b-form-input placeholder="Colore permuta"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Targa della permuta">
                            <b-form-input placeholder="00000000"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Chilometraggio della permuta">
                            <b-form-input placeholder="000.000 km"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Cilindrata della permuta">
                            <b-form-input placeholder="Specifica la cilindrata"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Accessori della permuta">
                            <b-form-input placeholder="Specifica accessori"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Valutazione della permuta">
                            <b-form-input placeholder="Specifica il valore della permuta in €"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </tab-content>

            <tab-content title="Riepilogo importi">
                <h2 class="text-primary-light my-2">Riepilogo importi: </h2>

                <b-row>

                <b-col md="3">

                    <b-form-group label="Prezzo auto di vendita al privato">
                        <b-form-input placeholder="Specifica prezzo in €"/>
                    </b-form-group>

                </b-col>

                <b-col md="3">

                    <b-form-group label="Somma del prezzo di tutti i servizi">
                        <b-form-input placeholder="Specifica prezzo in €"/>
                    </b-form-group>

                </b-col>

                <b-col md="3">

                    <b-form-group label="Somma del prezzo di tutti gli accessori">
                        <b-form-input placeholder="Specifica prezzo in €"/>
                    </b-form-group>

                </b-col>

                </b-row>

                    <h2 class="text-primary-light my-2">Importo totale</h2>

                    <b-row>
                        <b-col md="6">
                            <b-form-group label="Importo totale">
                                <b-form-input placeholder="Specifica prezzo in €"/>
                            </b-form-group>

                            <p>L'importo totale è comprensivo di servizi e <br> accessori, con importo permuta scalato.</p>
                        </b-col>
                    </b-row>
                
            </tab-content>

            <tab-content title="Modalità di pagamento">

                <b-row>
                    <b-col md="6">
                        <b-form-group label="Prezzo auto" label-cols="2">
                            <b-form-input placeholder="Qui viene specificato il prezzo auto in €"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label="Prezzo permuta" label-cols="2">
                            <b-form-input placeholder="Qui viene specificato il prezzo auto in €"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label="Sconto applicato" label-cols="2">
                            <b-form-input placeholder="Qui viene specificato il prezzo auto in €"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label="Importo residuo" label-cols="2">
                            <b-form-input placeholder="Qui viene specificato il prezzo auto in €"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6">
                        <b-form-group label="Importo pattuito" label-cols="2">
                            <b-form-input placeholder="Qui viene specificato il prezzo auto in €"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="6">
                        <b-form-group label="Acconto" label-cols="2">
                            <b-form-input placeholder="Qui viene specificato il prezzo auto in €"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <h2 class="text-primary-light my-2">Imposta i dati del finanziamento</h2>

                <b-row>

                    <b-col md="4">
                        <b-form-group label="Importo da finanziare">
                            <b-form-input placeholder="Specifica importo in €"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Numero rate">
                            <b-form-input placeholder="Specifica importo in €"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="4">
                        <b-form-group label="Importo della rata">
                            <b-form-input placeholder="Specifica importo in €"/>
                        </b-form-group>
                    </b-col>

                    <b-col md="12">
                        <b-form-group label="nota" label-cols="1">
                            <b-form-input placeholder="Inserisci note ed estensioni"/>
                        </b-form-group>
                    </b-col>

                </b-row>

            </tab-content>

            <tab-content title="Note">
                <h2 class="text-primary-light">Info aggiuntive</h2>

                <b-row>
                    <b-col md="4">
                        <b-form-group label="Data prevista consegna">
                            <b-form-input placeholder="gg/mm/aaaa"/>
                        </b-form-group>
                    </b-col>
                </b-row>

                <h2 class="text-primary-light my-2">Note contratto: </h2>

                <b-form-group label="Note contratto: ">
                    <b-form-textarea placeholder="Scrivi note relative al contratto..." :rows="4"/>
                </b-form-group>

                <b-form-group label="Note Interne: ">
                    <b-form-textarea placeholder="Scrivi note destinate all'uso interno..." :rows="4"/>
                </b-form-group>

                
            </tab-content>

        </form-wizard>
      </div>

    </b-card>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BCard,
  BBreadcrumb,
  BBreadcrumbItem,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";

import {FormWizard, TabContent} from 'vue-form-wizard';
import vSelect from 'vue-select';
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  components: {
    BCol,
    BRow,
    BButton,
    BCard,
    BBreadcrumb,
    BBreadcrumbItem,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    FormWizard,
    TabContent,
    vSelect,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
